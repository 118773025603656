<template>
  <form-dialog :title="title" :formItems="formItems" :formData="formData" @close="$emit('close')" @confirm="confirm">
    <template v-slot:path>
      <area-cascader v-model="formData.path" @select="selectArea" :disabled="!!detail" />
    </template>
  </form-dialog>
</template>

<script>
import { ApiBuilding } from "@/api/building";
import { searchEnableTagDic } from "@/api/common";

export default {
  name: "EditBuildingDialog",
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formItems: [
        {
          label: "楼盘名称",
          prop: "name",
          type: "input",
          rules: [{ required: true, message: "请填写", trigger: "blur" }]
        },
        {
          label: "楼盘别名",
          prop: "aliasName",
          type: "input"
          // rules: [{ required: true, message: "请填写", trigger: "blur" }]
        },
        {
          label: "开发商",
          prop: "developerCode",
          type: "select",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        {
          label: "楼盘地址",
          prop: "path",
          slot: true,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        {
          label: "",
          prop: "address",
          type: "textarea",
          placeholder: "请填写楼盘详细地址",
          rules: [{ required: true, message: "请填写", trigger: "blur" }]
        }
      ],
      formData: {
        name: "",
        aliasName: "",
        developerCode: "",
        path: "",
        pathName: "",
        address: ""
      }
    };
  },
  computed: {
    title() {
      return this.detail ? "编辑楼盘" : "新增楼盘";
    }
  },
  created() {
    this.initDeveloperOptions();
    this.initData();
  },
  methods: {
    // 初始化开发商列表
    initDeveloperOptions() {
      searchEnableTagDic("developer").then((res) => {
        res.forEach((item) => {
          item.disabled = !item.enabled;
        });
        this.$set(this.formItems[2], "options", res);
      });
    },
    initData() {
      if (!this.detail) return;
      const { path, pathName } = this.detail;
      this.formData = {
        ...this.detail,
        path: path.split("/"),
        pathName: pathName.split("/")
      };
    },
    selectArea({ pathLabels }) {
      this.formData.pathName = pathLabels;
    },
    confirm() {
      const { address, developerCode, name, aliasName, path, pathName } = this.formData;
      const params = { address, developerCode, name, aliasName, path: path.join("/"), pathName: pathName.join("/") };
      if (this.detail) {
        params.id = this.detail.id;
      }
      ApiBuilding.addOrUpdate(params).then(() => {
        this.$message.success(params.id ? "修改成功" : "添加成功");
        this.$emit("save-success");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
