<template>
  <div class="table-page">
    <ld-search-bar :formItems="searchForm" v-model="searchData" @search="onSearch"> </ld-search-bar>
    <ld-table
      rightMenuWidth="240px"
      :columns="tableColumns"
      :tableData="tableData"
      :loading="tableLoading"
      :pageInfo.sync="pageInfo"
      @update-data="initTableData"
    >
      <template v-slot:handleBarLeft>
        <el-button type="primary" size="small" @click="showEditDialog(null)" v-if="hasPermission"> 新增项目 </el-button>
      </template>
      <template v-slot:rightMenu="{ scope }">
        <table-button type="primary" @click="onDetail(scope.row)"> 项目配置 </table-button>
        <template v-if="hasPermission">
          <table-button @click="showEditDialog(scope.row)"> 编辑 </table-button>
          <table-button type="danger" @click="onDelete(scope.row.id)"> 删除 </table-button>
        </template>
      </template>
    </ld-table>
    <EditProjectDialog
      v-if="editProjectDialogVisible"
      :detail="currentRow"
      @close="editProjectDialogVisible = false"
      @success="editProjectSuccess"
    ></EditProjectDialog>
  </div>
</template>

<script>
import { ApiProject } from "@/api/building";
import { searchEnableTagDic } from "@/api/common";
import { mapGetters } from "vuex";
import { findCommonByArray } from "@/utils/util";
import EditProjectDialog from "./components/EditProjectDialog";

export default {
  name: "ProjectList",
  components: {
    EditProjectDialog
  },
  data() {
    return {
      searchForm: {
        name: {
          label: "项目名称",
          type: "input"
        },
        realestateId: {
          label: "楼盘名称",
          type: "select",
          options: []
        },
        projectType: {
          label: "项目类型",
          type: "select",
          options: []
        },
        developerCode: {
          label: "开发商",
          type: "select",
          options: []
        },
        modelFlag: {
          label: "是否样板房",
          type: "select",
          options: [{label:'是',value:1},{label:'否',value:0}]
        }
      },
      searchData: {},
      tableColumns: [
        {
          label: "项目编号",
          prop: "code",
          width: 120
        },
        {
          label: "项目名称",
          prop: "name",
          width: 300
        },
        {
          label: "项目所用系统类型",
          prop: "sysProductName",
          width: 140
        },
        {
          label: "项目类型",
          prop: "projectTypeName",
          width: 120
        },
        {
          label: "是否样板房",
          prop: "modelFlagStr",
          width: 100
        },
        {
          label: "楼盘名称",
          prop: "realestateName",
          width: 240
        },
        {
          label: "开发商",
          prop: "developerName",
          width: 100
        },
        {
          label: "项目地址",
          prop: "pathName",
          width: 300
        },
        {
          label: "户型数量",
          prop: "count"
        },
        {
          label: "项目状态",
          prop: "statusStr"
        }
      ],
      tableData: [],
      tableLoading: false,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      editProjectDialogVisible: false,
      currentRow: null
    };
  },
  computed: {
    ...mapGetters(["roleIds"]),
    hasPermission() {
      return findCommonByArray(this.roleIds, this.$bushuAdminRoleIds).length;
    }
  },
  mounted() {
    this.initOptions();
    this.initTableData();
  },
  methods: {
    initOptions() {
      ApiProject.buildingsOptionsByUser().then((res) => {
        this.searchForm.realestateId.options = res;
      });
      searchEnableTagDic("project_type").then((res) => {
        this.searchForm.projectType.options = res;
      });
      searchEnableTagDic("developer").then((res) => {
        this.searchForm.developerCode.options = res;
      });
    },
    async initTableData() {
      const params = {
        ...this.pageInfo,
        ...this.searchData
      };
      this.tableLoading = true;
      const { list, total } = await ApiProject.list(params).finally(() => {
        this.tableLoading = false;
      });
      this.tableData = list;
      this.pageInfo.total = total;
    },
    onSearch() {
      this.pageInfo.pageNum = 1;
      this.initTableData();
    },
    showEditDialog(row) {
      this.currentRow = row;
      this.editProjectDialogVisible = true;
    },
    editProjectSuccess() {
      this.editProjectDialogVisible = false;
      this.initTableData();
    },
    onDetail(row) {
      const { id: projectId, name: projectName, realestateId, projectType, gatewayFlag, sysProductId } = row;
      this.$router.push({
        path: "/building/projectList/projectDetail/baseInfo",
        query: {
          tab: "基本信息",
          projectId,
          projectName,
          realestateId,
          projectType,
          gatewayFlag,
          sysProductId
        }
      });
    },
    onDelete(id) {
      this.$confirm("此操作将永久删除该项, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      })
        .then(() => {
          ApiProject.del(id).then(() => {
            this.$message.success("删除成功");
            this.initTableData();
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
