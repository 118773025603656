/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/utils/downloadExcel.js
 */
/**
 * @description 后台传回来表格数据流进行下载
 * @author YHY
 */
export function downloadExcel(title, data, type = "application/vnd.ms-excel") {
  let blob = new Blob([data], { type });
  const a = document.createElement("a");
  // 生成文件路径
  const href = window.URL.createObjectURL(blob);
  a.href = href;
  a.download = title;
  // 利用a标签做下载
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(href);
}
