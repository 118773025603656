<template>
  <div class="table-page">
    <ld-search-bar :formItems="searchForm" v-model="searchData" @search="onSearch"> </ld-search-bar>
    <ld-table
      :rightMenuWidth="isAdmin ? 380 : 250"
      :columns="tableColumns"
      :tableData="tableData"
      :loading="tableLoading"
      :pageInfo.sync="pageInfo"
      @update-data="initTableData"
    >
      <template v-slot:handleBarLeft>
        <el-button type="primary" size="small" @click="addItem(null)" v-if="isAdmin"> 新增楼盘 </el-button>
      </template>
      <template v-slot:rightMenu="{ scope }">
        <table-button type="success" @click="downloadDataTemplate(scope.row.id)">下载数据源模板</table-button>
        <table-button type="success" @click="downloadConfigTemplate(scope.row.id)">下载配置模板</table-button>
        <template v-if="isAdmin">
          <table-button @click="edit(scope.row)">编辑</table-button>
          <table-button type="danger" @click="remove(scope.row)">删除</table-button>
        </template>
      </template>
    </ld-table>
    <EditBuildingDialog
      v-if="editBuildingDialogVisible"
      :detail="currentRow"
      @close="editBuildingDialogVisible = false"
      @save-success="saveSuccess"
    />
  </div>
</template>

<script>
import { ApiBuilding } from "@/api/building";
import EditBuildingDialog from "./components/EditBuildingDialog";
import { downloadExcel } from "@/utils/downloadExcel.js";
import { findCommonByArray } from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "BuildingList",
  components: {
    EditBuildingDialog
  },
  data() {
    return {
      searchForm: {
        name: {
          label: "楼盘名称",
          type: "input"
        }
      },
      searchData: {},
      tableColumns: [
        {
          label: "楼盘编号",
          prop: "code",
          width: 100
        },
        {
          label: "楼盘名称",
          prop: "name",
          width: 150
        },
        {
          label: "楼盘别名",
          prop: "aliasName",
          width: 150
        },
        {
          label: "楼盘地址",
          prop: "addressComplete",
          "min-width": 200
        },
        {
          label: "项目名称",
          prop: "projectname",
          isLink: true,
          onLinkFunc: this.toProject,
          "min-width": 150
        },
        {
          label: "项目类型",
          prop: "projectsysProductName",
          width: 120
        },
        {
          label: "项目状态",
          prop: "projectstatusStr",
          width: 120
        },
        {
          label: "项目数量",
          prop: "projectCount",
          width: 120
        }
      ],
      tableData: [],
      tableLoading: false,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      editBuildingDialogVisible: false,
      currentRow: null
      // mergeColumn: [
      //   {
      //     prop: "code",
      //     colIndex: 0
      //   },
      //   {
      //     prop: "code",
      //     colIndex: 1
      //   },
      //   {
      //     prop: "code",
      //     colIndex: 2
      //   },
      //   {
      //     prop: "code",
      //     colIndex: 6
      //   },
      //   {
      //     prop: "code",
      //     colIndex: 7
      //   }
      // ]
    };
  },
  computed: {
    ...mapGetters(["roleIds"]),
    isAdmin() {
      return findCommonByArray(this.roleIds, this.$bushuAdminRoleIds).length;
    }
    // mergeRule() {
    //   let obj = {};
    //   this.mergeColumn.forEach(({ colIndex, prop }) => {
    //     let position = 0; //用于存储相同项的开始index
    //     obj[colIndex] = [];
    //     this.tableData.forEach((item, index) => {
    //       if (index === 0) {
    //         obj[colIndex].push(1);
    //         position = 0;
    //       } else {
    //         if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
    //           // 有相同项时，要把之前存储的数据+1
    //           obj[colIndex][position] += 1;
    //           // 名称相同后往数组里面加一项：0
    //           obj[colIndex].push(0);
    //         } else {
    //           // 不相同项时，要记住当前的index
    //           obj[colIndex].push(1);
    //           // 记录新的位置
    //           position = index;
    //         }
    //       }
    //     });
    //   });
    //   return obj;
    // },
  },
  mounted() {
    this.initTableData();
  },
  methods: {
    async initTableData() {
      const params = {
        ...this.pageInfo,
        ...this.searchData
      };
      this.tableLoading = true;
      const { list, total } = await ApiBuilding.page(params).finally(() => {
        this.tableLoading = false;
      });
      this.tableData = this.formatData(list);
      this.pageInfo.total = total;
    },
    formatData(data) {
      let list = [];
      data.forEach((item) => {
        let _item = { ...item };
        delete _item.projects;
        if (item.projects) {
          item.projects.forEach((projectItem) => {
            let _projectItem = {};
            let obj = {};
            for (const key in projectItem) {
              _projectItem[`project${key}`] = projectItem[key];
            }
            obj = { ..._item, ..._projectItem };
            list.push(obj);
          });
        } else {
          list.push(_item);
        }
      });
      return list;
    },
    // objectSpanMethod({ rowIndex, columnIndex }) {
    //   for (let i = 0; i < this.mergeColumn.length; i++) {
    //     const { colIndex } = this.mergeColumn[i];
    //     if (columnIndex === colIndex) {
    //       const _row = this.mergeRule[columnIndex][rowIndex];
    //       return {
    //         rowspan: _row,
    //         colspan: 1
    //       };
    //     }
    //   }
    // },
    onSearch() {
      this.pageInfo.pageNum = 1;
      this.initTableData();
    },
    // 查看项目
    toProject(row) {
      const { projectid, projectname, projectrealestateId, projecttype, projectsysProductId, projectgatewayFlag } = row;
      this.$router.push({
        path: "/building/projectList/projectDetail/baseInfo",
        query: {
          projectId: projectid,
          projectName: projectname,
          realestateId: projectrealestateId,
          projectType: projecttype,
          tab: "基本信息",
          gatewayFlag: projectgatewayFlag,
          sysProductId: projectsysProductId
        }
      });
    },
    addItem() {
      this.currentRow = null;
      this.editBuildingDialogVisible = true;
    },
    edit(row) {
      this.currentRow = row;
      this.editBuildingDialogVisible = true;
    },
    saveSuccess() {
      this.editBuildingDialogVisible = false;
      this.initTableData();
    },
    // 删除
    remove({ id }) {
      this.$delItem(() => {
        ApiBuilding.remove(id).then(() => {
          this.$message.success("删除成功");
          this.initTableData();
        });
      });
    },
    downloadDataTemplate(realestateId) {
      ApiBuilding.downloadDataTemplate({
        realestateId
      }).catch((res) => {
        if (res) {
          downloadExcel(`数据源模板.xlsx`, res);
        }
      });
    },
    downloadConfigTemplate(realestateId) {
      ApiBuilding.downloadConfigTemplate(realestateId);
    }
  }
};
</script>
