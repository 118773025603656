<template>
  <form-dialog
    labelWidth="130px"
    width="540px"
    :title="title"
    :formItems="formItems"
    :formData="formData"
    @change="onChange"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <template v-slot:logoPath>
      <UploadImg :img.sync="formData.logoPath" />
    </template>
  </form-dialog>
</template>

<script>
import UploadImg from "@/components/upload/UploadImg.vue";
import { ApiProject } from "@/api/building";
import { searchEnableTagDic } from "@/api/common";

export default {
  name: "EditProjectDialog",
  components: {
    UploadImg
  },
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formItems: {
        name: {
          label: "项目名称",
          prop: "name",
          type: "input",
          rules: [{ required: true, message: "请输入", trigger: "blur" }]
        },
        realestateId: {
          label: "楼盘名称",
          prop: "realestateId",
          type: "select",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        address: {
          label: "项目地址",
          prop: "address",
          type: "read"
        },
        sysProductId: {
          label: "项目所用系统类型",
          prop: "sysProductId",
          type: "select",
          options: []
        },
        projectType: {
          label: "项目类型",
          prop: "projectType",
          type: "select",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        gatewayFlag: {
          label: "是否含有网关",
          prop: "gatewayFlag",
          type: "radio",
          options: [
            {
              label: "是",
              value: 1
            },
            {
              label: "否",
              value: 0
            }
          ],
          border: false,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        modelFlag: {
          label: "是否样板房",
          prop: "modelFlag",
          type: "radio",
          options: [
            {
              label: "是",
              value: 1
            },
            {
              label: "否",
              value: 0
            }
          ],
          border: false,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        localGwFlag: {
          label: "是否有数采",
          prop: "localGwFlag",
          type: "radio",
          options: [
            {
              label: "是",
              value: 1
            },
            {
              label: "否",
              value: 0
            }
          ],
          border: false,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        interactType: {
          label: "项目连接方式",
          prop: "interactType",
          type: "select",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        connectDevice: {
          label: "485连接设备",
          prop: "connectDevice",
          type: "select",
          options: []
        },
        offlineKanbanFlag: {
          label: "离线总览展示",
          prop: "offlineKanbanFlag",
          type: "checkbox",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        status: {
          label: "项目状态",
          prop: "status",
          type: "select",
          options: [],
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        developer: {
          label: "开发商",
          prop: "developer",
          type: "read"
        },
        projectManager: {
          label: "项目经理",
          prop: "projectManager",
          type: "input"
        },
        logoPath: {
          label: "公司logo",
          prop: "logoPath",
          slot: true
        }
      },
      formData: {}
    };
  },
  computed: {
    title() {
      return `${this.detail ? "编辑" : "新增"}项目`;
    }
  },
  mounted() {
    this.initOptions();
    this.initData();
  },
  methods: {
    initOptions() {
      ApiProject.buildingsOptionsByUser().then((res) => {
        this.formItems.realestateId.options = res;
      });
      ApiProject.systemProjectOptions().then((res) => {
        this.formItems.sysProductId.options = res;
      });
      searchEnableTagDic("project_type").then((res) => {
        this.formItems.projectType.options = res;
      });
      ApiProject.projectConnectionMode().then((res) => {
        this.formItems.interactType.options = res;
      });
      ApiProject.connectDeviceOptions().then((res) => {
        this.formItems.connectDevice.options = res;
      });
      ApiProject.offlineKanbanFlagOpitons().then((res) => {
        this.formItems.offlineKanbanFlag.options = res;
      });
      ApiProject.projectStatusOptions().then((res) => {
        this.formItems.status.options = res;
      });
    },
    initData() {
      if (!this.detail) return;
      const { realestateId } = this.detail;
      this.formData = {
        ...this.detail,
        realestateId: Number(realestateId)
      };
      this.changeBuildings();
    },
    onChange({ prop, value }) {
      switch (prop) {
        case "realestateId":
          this.changeBuildings();
          break;
        case "gatewayFlag":
          this.changeGateway(value);
          break;
        default:
          break;
      }
    },
    changeBuildings() {
      ApiProject.getDeveloperAndAddress(this.formData.realestateId).then((res) => {
        const { developerName, pathName } = res;
        this.$set(this.formData, "address", pathName);
        this.$set(this.formData, "developer", developerName);
      });
    },
    changeGateway(value) {
      if (this.detail) {
        this.$confirm("已修改“是否含有网关”，请确认是否修改?", "提示", {
          confirmButtonText: "修改",
          cancelButtonText: "不修改",
          type: "warning"
        }).catch(() => {
          this.formData.gatewayFlag = value == 1 ? 0 : 1;
        });
      }
    },
    onConfirm() {
      const params = {
        ...this.formData
      };
      ApiProject.addOrUpdate(params).then(() => {
        this.$message.success(`${params.id ? "编辑" : "新增"}成功`);
        this.$emit("success");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
